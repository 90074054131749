<template>
  <div class="page">
    <div class="page-header">
      <div>
        <a-input
          allowClear
          placeholder="id"
          v-model="params.id"
          @pressEnter="search"
          size="small"
          class="m-5"
          style="width: 100px"
        />
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @search="getBigCoin"
          @change="changeVersionlistSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          class="w-200 ml-5"
          v-model="params.coinId"
          placeholder="大币种"
        >
          <a-select-option
            v-for="item of bigCoinList"
            :key="item.coinId"
            :value="item.coinId"
          >{{ item.coinName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="coinItemChange"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin-left: 5px; width: 120px"
          class="m-5"
          v-model="params.itemId"
          placeholder="小币种"
        >
          <a-select-option
            v-for="item of smallCoinList"
            :key="item.coinKindItemId"
            :value="item.coinKindItemId"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @select="search"
          showSearch
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          class="m-5"
          style="margin-left: 5px; width: 260px"
          v-model="params.versionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
            :label="item.coinVersionName"
            :class="item.isDuiban ? 'color-green' : ''"
          >
            {{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}
          </a-select-option>
        </a-select>
        <!-- 数据来源 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="margin-left: 5px; width: 140px"
          class="m-5"
          v-model="params.type"
          placeholder="数据来源"
        >
          <a-select-option
            v-for="item of sourceList"
            :key="item.value"
            :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <!-- 数据来源 -->
         <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="margin-left: 5px; width: 140px"
            class="m-5"
            v-model="isDiku"
            placeholder="底库是否存在"
        >
          <a-select-option
              v-for="item of isDiKuTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <!-- 状态筛选 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            style="margin-left: 5px; width: 120px"
            class="m-5"
            v-model="params.waitStatus"
            placeholder="状态筛选"
        >
          <a-select-option
              v-for="item of waitStatusList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            size="small"
            class="w-150 ml-5"
            v-model="params.isDuiban"
            placeholder="是否支持对版"
        >
          <a-select-option
              v-for="item of isDuiBanList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>

        <a-radio-group
            size="small"
            v-model="imageType"
            :default-value="0"
            button-style="solid"
            class="m-5"
        >
          <a-radio-button :value="0">
            正面图
          </a-radio-button>
          <a-radio-button :value="1">
            背面图
          </a-radio-button>
          <a-radio-button :value="2">
            正反面
          </a-radio-button>
        </a-radio-group>
        <a-button
          @click="search"
          size="small"
          class="btn-item m-5"
          type='primary'
        >搜索</a-button>
        <a-button
          class="btn-item m-5"
          size="small"
          @click="uploadClick()"
        >上传</a-button>
<!--    查看实物与拓片图    -->
        <ShowVersionImg class="ml-20" v-if="showVersionImgStatus" :info="showVersionImg"/>
      </div>
<!--      描述    -->
      <EditorVersionDescInfo v-if="showVersionImgStatus" :info="list[0]" @changeVersionDesc="handleChangeVersionDesc($event)" />
    </div>
    <div class="page-body">
      <ConfirmListV2
          v-show="imageType !== 2"
          :list="list"
          :imageType="imageType"
          @deleteItem="getList"
          @editSuccess="getList"
          @cutPicSuccess="handleCutPicSuccess"
          @sameVersion="handleShowSameVersion"
          @changeVersionDesc="handleChangeVersionDesc($event)"
      />
      <ConfirmList
          v-show="imageType === 2"
          :list="list"
          @deleteItem="getList"
          @editSuccess="getList"
          @cutPicSuccess="handleCutPicSuccess"
          @sameVersion="handleShowSameVersion"
          @changeVersionDesc="handleChangeVersionDesc($event)"
      />
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            @change="changePage"
            @editSuccess="editSuccess"
        />
      </div>
    </div>
    <ModalUpload ref="upload" @success="getList"></ModalUpload>
  </div>
</template>

<script>
import {
  isDiKuTypeList,
  isDuiBanList,
  sourceList,
  waitStatusList
} from "@/views/cmsPage/versionManage/sampleStore/data.js";
import ModalUpload from "@/views/cmsPage/versionManage/sampleStore/ModalUpload.vue";
import ConfirmList from "@/views/cmsPage/versionManage/sampleStore/_components/ConfirmList";
import ConfirmListV2 from "@/views/cmsPage/versionManage/sampleStore/_components/ConfirmListV2";
import untils from "@/untils";
import {getSampleStoreByIdApi, getVersionListApi} from "@/views/cmsPage/versionManage/sampleStore/_apis"
import ShowVersionImg from "@/views/cmsPage/versionManage/sampleStore/_components/ShowVersionImg/index.vue"
import EditorVersionDescInfo
  from "@/views/cmsPage/versionManage/sampleStore/_components/EditorVersionDescInfo/index.vue"
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      untils,
      isDiKuTypeList,
      waitStatusList,
      isDuiBanList,
      imageType: 0, //正面 1背面
      isTest: undefined,
      isDiku: undefined,
      sourceList: sourceList,
      selctedArrays: [],
      list: [],
      loading: false,
      detail: "",
      params: {
        pageNum: 1,
        pageSize: 20,
        id: undefined,
        isDiku: undefined,
        waitStatus: undefined,
        versionId: undefined,
        isDuiban: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      isShowFrontAndBackImgStatus: false,
      showVersionImg: {
        shiwuImgFront: '',
        shiwuImgBack: '',
        tapianImgFront: '',
        tapianImgBack: ''
      },
      showVersionImgStatus: false
    };
  },
  computed: {
    isDisabled() {
      return this.selctedArrays.length <= 0;
    },
  },
  filters: {
    versionText(item) {
      return `${item.versionName}[${item.aliasName || ""}](${item.size})`;
    },
  },
  components: {
    EditorVersionDescInfo,
    ModalUpload,
    ConfirmList,
    ConfirmListV2,
    ShowVersionImg,
  },
  async mounted() {
    const { id } = this.$route.query;
    this.$set(this.params, "id", id);
    await this.getList();
  },
  methods: {
    /** 获取版别图片 */
    async handleSearchVersionImg() {
      const res = await getVersionListApi(this.params.versionId)
      if (res.status !== '200') return
      const resData = res.data.records[0]
      this.showVersionImg.shiwuImgFront = resData.front_img
      this.showVersionImg.shiwuImgBack = resData.contrary_img
      this.showVersionImg.tapianImgFront = resData.coinKindVersionImage
      this.showVersionImg.tapianImgBack = resData.coinKindVersionBackImage
      this.showVersionImgStatus = true
    },
    /** 修改版别描述 */
    async handleChangeVersionDesc(info) {
      this.$loading.show()
      const res = await getSampleStoreByIdApi({id: info.id, pageNum: 1})
      this.$loading.hide()
      if (res.status !== '200') return;
      const tempDataList = res.data.records
      this.list.forEach(item => {
        if (item.id === info.id) {
          item.internalMark = tempDataList[0].internalMark
          item.versionDesc = tempDataList[0].versionDesc
        }
      })
    },
    /** 同版 */
    async handleShowSameVersion(data) {
      await this.getBigCoin(data.coinKindName)
      this.params.id = undefined
      this.sameVersionData = data
      this.params.coinId = data.coinId
      this.params.id = undefined
      await this.changeVersionlistSearch(data.coinId)
      this.params.itemId = data.coinItemId
      await this.getSmallCoinList(data.coinItemId)
      this.params.versionId = data.coinVersionId
      await this.getVersion()
      await this.search()
    },
    /** 裁剪 */
    async handleCutPicSuccess(id) {
      const res = await this.axios("/dq_admin/duibanSampleData/list", {
        params: {id: id},
      });
      if (res.status !== '200') return;
      const tempData = res.data.records[0]
      this.list.forEach(el => {
        if (el.id === id) {
          el.cutBackImage = tempData.cutBackImage
          el.cutFrontImage = tempData.cutFrontImage
        }
      })
    },
    editSuccess() {
      this.getList()
    },
    uploadClick() {
      this.$refs["upload"].show(this.params);
    },
    // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays));
    },
    // 获取大币种
    async getBigCoin(str) {
      const res = await getBigCoinList(str, [5])
      if (res.status !== '200') return;
      this.bigCoinList = res.data.records.map(el => ({coinName: el.coinKindName, coinId: el.id}));
    },
    // 选择大币种
    async changeVersionlistSearch(val) {
      delete this.params.itemId;
      delete this.params.versionId;
      if (!val) {
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      await this.getSmallCoinList();
    },
    // 查询小币种
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinItems", {
        params: {
          coinId: this.params.coinId,
        },
      });
      if (res.status !== '200') return;
      this.smallCoinList = res.data;
    },
    // 选择小币种
    async coinItemChange(val) {
      delete this.params.versionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      await this.getVersion();
    },
    // 获取版别
    async getVersion() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinVersions", {
        params: {
          coinId: this.params.coinId,
          coinItemId: this.params.itemId,
        },
      });
      if (res.status !== '200') return;
      this.versionList = res.data;
    },
    // 分页
    async changePage(page) {
      this.$set(this.pagination, "current", page);
      this.$set(this.params, "pageNum", page);
      await this.getList();
    },

    // 数据列表
    async getList() {
      if (Number(this.isTest) === 1) {
        this.params.isTest = 1
      }
      this.loading = true;
      this.showVersionImgStatus = false
      this.isDiku === 2 ? this.params.isDiku = undefined : this.params.isDiku = this.isDiku
      const res = await this.axios("/dq_admin/duibanSampleData/list", {
        params: this.params,
      });
      this.loading = false;
      if (res.status !== '200') return;
      this.pagination.total = res.data.total;
      this.list = res.data.records;
      if (this.params.versionId) {
        await this.handleSearchVersionImg()
      } else {
        this.showVersionImgStatus = false
      }
    },
    // 点击搜索按钮
    async search() {
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      await this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.btn-item {
  margin-left: 10px;
}
.pagination {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.ml-5 {
  margin-left: 5px;
}
.m-5 {
  margin: 5px;
}
</style>