<template>
  <div>
    <div class="list">
      <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
      >
        <div class="content">
          <div class="image-box" :id="'img-back' + index">
            <div ref="imgBoxEl">
              <img
                  id="img-box-el"
                  class="image"
                  mode="aspectFill"
                  :src="showImage(item)"
                  @click="previewImg(index)"
              />
            </div>
            <div class="tip" :style="{color: item.isDiku ? '' : 'red'}">{{item.id}}</div>
            <div class="tip-sham color-red font-size-24" v-if="item.waitStatus === 4">假</div>
            <div class="tip-lock color-red font-size-24" v-if="item.isLock"><a-icon type="lock" /></div>
          </div>

          <div class="name">
            {{`${item.coinKindName}-${item.coinKindItemName}-${item.coinKindItemVersionName}`}}
            <span class="color-green" v-if="item.isTest">（{{ item.isTest ? '测试集' : ''  }}）</span>
          </div>
        </div>
        <div class="footer">
          <a-button
              class="move-btn"
              size="small"
              type="link"
              style="margin-right: 5px"
              @click="showYuanImg(item)"
          >原图</a-button>
          <a-button
              size="small"
              type="link"
              style="margin-right: 5px; color: orangered"
              @click="moveClick(item)"
          >挪动</a-button>
          <a-button
              class="move-btn"
              size="small"
              type="link"
              style="margin-right: 5px"
              @click="handleSameVersion(item)"
          >同版</a-button>
          <a-dropdown>
            <a-button size="small" type="link" style="color: blue">更多</a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a-button
                      size="small"
                      type="primary"
                      @click="logClick(item)"
                  >日志</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-button
                      size="small"
                      type="primary"
                      style="margin-right: 5px"
                      @click="handleVersion(item)"
                  >对版</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      title="确定要质检此项么？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="handleCheck(item.id)"
                  >
                    <a-button size="small" type="primary">质检</a-button>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <a-button
                      size="small"
                      type="primary"
                      class="setup-btn"
                      @click="handleCutPic(item)"
                  >机器裁剪</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-button
                      class="move-btn"
                      size="small"
                      type="primary"
                      style="margin-right: 5px"
                      @click="handleCutPicByHand(item)"
                  >手动裁剪</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-button
                      size="small"
                      type="primary"
                      style="margin-right: 5px"
                      @click="handlePushCoinBookData(item)"
                  >推送到活谱数据管理</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-button
                      size="small"
                      type="primary"
                      style="margin-right: 5px"
                      @click="handleSetCoverPic(item)"
                  >设为封面</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      :title="item.isLock ? '确定取消锁定？' : '确定设置锁定？'"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="handleSetShamOrLock(item.id, 'lock', item.isLock ? 0 : 1)"
                  >
                    <a-button size="small" type="danger">{{ item.isLock ? '取消锁定' : '设置锁定' }}</a-button>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      :title="item.waitStatus === 4 ? '确定取消假币？' : '确定设置此项为假币？'"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="handleSetShamOrLock(item.id, 'sham', item.waitStatus === 4 ? 0 : 4)"
                  >
                    <a-button size="small" type="danger">{{ item.waitStatus === 4 ? '取消假币' : '设置假币' }}</a-button>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      title="确定要删除此项么？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="handleDeleteItem(item.id)"
                  >
                    <a-button size="small" type="danger">删除</a-button>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
    <ConfirmLog ref="log"></ConfirmLog>
    <ChangeData @success="editSuccess" ref="change" />
    <ShowImg ref="showImgEl"/>
    <!--  对版   -->
    <VersionPic ref="showVersionPicEl"/>
    <VersionPicV2 ref="showVersionPicV2El"/>
<!--  推送到活谱数据管理  -->
    <PushCoinBookNaturePopup ref="pushCoinBookNaturePopupEl"/>
<!--  封面图  -->
    <SetCoverPicPopup ref="setCoverPicPopupEl"/>
  </div>
</template>

<script>
import ConfirmLog from "@/views/cmsPage/versionManage/sampleStore/ConfirmLog.vue";
import ShowImg from "@/views/cmsPage/versionManage/duibanconfirm/_components/ShowImg";
import {sourceValue} from "@/views/cmsPage/versionManage/sampleStore/data";
import ChangeData from "@/views/cmsPage/versionManage/sampleStore/_components/ChangeData";
import untils from "@/untils";
import VersionPic from "@/views/cmsPage/versionManage/sampleStore/_components/VersionPic";
import VersionPicV2 from "@/views/cmsPage/versionManage/sampleStore/_components/VersionPicV2"
import PushCoinBookNaturePopup
  from "@/views/cmsPage/versionManage/sampleStore/_components/PushCoinBookNaturePopup/index.vue"
import SetCoverPicPopup from "@/views/cmsPage/versionManage/sampleStore/_components/SetCoverPicPopup/index.vue"
import {getPushCoinBookBeforeStatus, setLockOrShamCoinApi} from "@/views/cmsPage/versionManage/sampleStore/_apis"
export default {
  props: {
    list: {
      default: [],
    },
    imageType: {
      default: 0, //0正面 1背面
    },
  },
  data() {
    return {
      frontOrBackList: [],
    };
  },
  components: {
    SetCoverPicPopup,
    PushCoinBookNaturePopup,
    ChangeData,
    ConfirmLog,
    ShowImg,
    VersionPic,
    VersionPicV2,
  },
  methods: {
    /** 锁定或设置假币 */
    async handleSetShamOrLock(id, type, targetValue) {
      const res = await setLockOrShamCoinApi({id, type, targetValue})
      if (res.status !== '200') return
      this.$emit("editSuccess");
    },
    /** 设为封面图 */
    async handleSetCoverPic(item) {
      const res = await getPushCoinBookBeforeStatus(item.id)
      if (res.status !== '200') return
      await this.$refs.setCoverPicPopupEl.show(item)
    },
    /** 推送到活谱数据管理，实物图 */
    async handlePushCoinBookData(item) {
      const res = await getPushCoinBookBeforeStatus(item.id)
      if (res.status !== '200') return
      const tempData = {
        coinId: item.coinId,
        coinSid: item.coinSid,
        coinItemId: item.coinItemId,
        coinItemVersionId: item.versionId,
        coinKindItemVersionName: item.coinKindItemVersionName,
        frontImage: item.frontImage,
        backImage: item.backImage,
        coinName: item.coinKindName
      }
      await this.$refs.pushCoinBookNaturePopupEl.show(tempData)
    },
    /** 手动裁剪 */
    handleCutPicByHand(item) {
      // this.$refs.handCutPicEl.showPopup({front: item.frontImage , back: item.backImage, id: item.id})
      const _this = this
      this.coverImg({
        title: '裁切图片',
        list: [item.frontImage, item.backImage],
        then: async (data)=>{
          const { front, contrary } = data
          this.$loading.show()
          const res = await this.axios.post('/dq_admin/duibanSampleData/sampleHandCut', {
            sampleId: item.id,
            cutFrontImage: front,
            cutBackImage: contrary
          })
          this.$loading.hide()
          if (res.status !== '200') return
          this.$message.info(res.message || '成功')
          _this.handleHandCutSuccess(item.id)
        }
      })
    },
    /** 同版 */
    handleSameVersion(item) {
      const data = {
        coinId: item.coinId,
        coinItemId: item.coinItemId,
        coinVersionId: item.versionId
      }
      this.$emit('sameVersion', data)
    },
    /** 质检 */
    async handleCheck(id) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanSampleData/pushQualityCheck", {
        params: { recordId: id },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("editSuccess");
    },
    /** 手动裁剪 */
    handleHandCutSuccess(id) {
      this.$emit('cutPicSuccess', id)
    },
    /** 裁剪 */
    async handleCutPic(item) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanSampleData/sampleCut", {
        params: {ids: item.id}
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || '操作成功')
      this.$emit('cutPicSuccess', item.id)
    },
    /** 对版功能 */
    handleVersion(item) {
      // this.$refs.showVersionPicEl.show(item.id)
      this.$refs.showVersionPicV2El.show(item, 1, item.versionId, false)
    },
    showSourceValue(item) {
      return sourceValue(item.sourceType)
    },
    showYuanImg(item) {
      this.$refs.showImgEl.show({front: item.frontImage , back: item.backImage})
    },
    /** 查看日志 */
    logClick(item){
      this.$refs["log"].show(item)
    },
    editSuccess() {
      this.$emit("editSuccess");
    },
    /** 挪动 */
    moveClick(item) {
      if (item.isLock) return this.$message.warning('当前样本已锁定，请先解锁')
      this.$refs.change.show(item);
    },
    // 推送到质检
    async pushClick(id) {
      const res = await this.axios("/dq_admin/duibanConfirm/confirmationData", {
        params: { recordId: id },
      });
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("editSuccess");
    },
    showImage(item) {
      if (this.imageType === 0) {
        return item.cutFrontImage || item.frontImage;
      } else {
        return item.cutBackImage || item.backImage;
      }
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        let url
        if (this.imageType === 0) {
          url = el.cutFrontImage || el.frontImage;
        } else {
          url = el.cutBackImage || el.backImage;
        }
        return {
          img_url: url,
        };
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 复制 */
    handleCopyText(text) {
      if (text) {
        untils.copy_text(text)
      } else {
        this.$message.warn('当前类型不支持复制')
      }
    },
    /** 删除 */
    async handleDeleteItem(id) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/duibanSampleData/delById", {
        params: {
          recordId: id + "",
        },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("deleteItem", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .tip-lock {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 30px;
          height: 30px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
        .tip-sham {
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
          height: 30px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
    }
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>