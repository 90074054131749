<template>
  <div class="editor-version-desc-info-pages">
    <div  class="item-info">
      <a-popover
          title="版别描述"
          trigger="hover"
      >
        <template slot="content">
          <a-input
              size="small"
              type="textarea"
              :placeholder="versionDescType ? '内部版别描述' : '外部版别描述'"
              :rows="5"
              class="w-300"
              @change="handleInputVersionDesc"
              :value="versionDescType ? info.internalMark : info.versionDesc"
          ></a-input>
          <div class="flex-sp-bt-center mt-10">
            <a-radio-group v-model="versionDescType" :default-value="1" button-style="solid" size="small">
              <a-radio-button :value="1">内部标记</a-radio-button>
              <a-radio-button :value="0">外部标记</a-radio-button>
            </a-radio-group>
            <a-button size="small" type="primary" class="ml-10" @click="handleEditVersionDesc">{{ versionDescType ? '修改内部' : '修改外部'}}</a-button>
          </div>
        </template>
        <div class="mt-10 w-100-w flex-start-center">
          版别介绍（{{ versionDescType ? '内部' : '外部' }}）：
          <span class="font-weight-bold color-blue">{{ versionDescType ? info.internalMark : info.versionDesc }}</span>
        </div>
      </a-popover>
    </div>
  </div>
</template>
<script>
import {postChangeVersionDescOrMark} from "@/views/cmsPage/versionManage/sampleStore/_apis"

export default {
  props: ['info'],
  data() {
    return {
      versionDescType: 1,
      params: {
        id: '',
      },
      versionOtherDesc: '',
      versionOtherMark: ''
    }
  },
  watch: {
    /** 计算显示内部描述还是外部描述 */
    info: {
      handler(newVal, oldVal) {
        if ((newVal.internalMark || '').trim() && (newVal.versionDesc || '').trim()) {
          this.versionDescType = 1
        } else {
          if ((newVal.internalMark || '').trim()) {
            this.versionDescType = 1
          } else if ((newVal.versionDesc || '').trim()) {
            this.versionDescType = 0
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /** 编辑版别描述 */
    async handleEditVersionDesc() {
      this.params.id = this.info.versionId
      this.params.internalMark = this.versionOtherMark
      this.params.versionDesc = this.versionOtherDesc
      if (this.versionDescType) {
        delete this.params.versionDesc
      } else {
        delete this.params.internalMark
      }
      const res = await postChangeVersionDescOrMark(this.params)
      if (res.status !== '200') return;
      this.$message.success("更改成功");
      this.$emit('changeVersionDesc', this.info)
    },
    /** 修改描述 */
    handleInputVersionDesc(e){
      if (this.versionDescType) {
        this.versionOtherMark = e.target.value
      } else {
        this.versionOtherDesc = e.target.value
      }
    },
  }
}
</script>
<style scoped lang="scss">
.editor-version-desc-info-pages {
  width: 100%;
}
.item-info {
  width: 100%;
}
</style>