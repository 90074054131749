<template>
  <a-modal
      v-model="showModal"
      title="设为封面预览"
      ok-text="确认设为封面"
      cancel-text="取消"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="content-modal">
      <div class="top-title" v-if="modalData">
        {{ modalData.coinKindName + '·' + modalData.coinKindItemName + '·' + modalData.coinKindItemVersionName }}
      </div>
      <a-divider>原图</a-divider>
      <div class="mt-20 width-100">
        <a-spin :spinning="yuanImgSpinningStatus" tip="Loading...">
          <div class="img-list">
            <div class="img-box">
              <img
                  v-if="frontImgUrlByYuan"
                  :src="frontImgUrlByYuan"
                  alt="" @click="handleShowBigImg([frontImgUrlByYuan, backImgUrlByYuan])"
              >
            </div>
            <div class="img-box">
              <img
                  v-if="backImgUrlByYuan"
                  :src="backImgUrlByYuan"
                  alt="" @click="handleShowBigImg([backImgUrlByYuan, frontImgUrlByYuan])"
              >
            </div>
          </div>
        </a-spin>
      </div>
      <a-divider>抠图之后的图片</a-divider>
      <div class="mt-20 width-100" >
        <a-spin :spinning="cutImgSpinningStatus" tip="Loading...">
          <div class="img-list">
            <div class="img-box">
              <img
                  v-if="frontImgUrlByCut"
                  :src="frontImgUrlByCut"
                  alt="" @click="handleShowBigImg([frontImgUrlByCut, backImgUrlByCut])"
              >
            </div>
            <div class="img-box">
              <img
                  v-if="backImgUrlByCut"
                  :src="backImgUrlByCut"
                  alt="" @click="handleShowBigImg([backImgUrlByCut, frontImgUrlByCut])"
              >
            </div>
          </div>
        </a-spin>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {
  getCutImgByCoverPic,
  getYuanImgByCoverPic,
  postSetCoverPic
} from "@/views/cmsPage/versionManage/sampleStore/_apis"

export default {
  data() {
    return {
      showModal: false,
      modalData: "",
      yuanImgSpinningStatus: true,
      cutImgSpinningStatus: true,
      frontImgUrlByYuan: "",
      backImgUrlByYuan: "",
      frontImgUrlByCut: "",
      backImgUrlByCut: "",
    }
  },
  methods: {
    /** 展示 */
    async show(data) {
      this.frontImgUrlByYuan = ""
      this.backImgUrlByYuan = ""
      this.frontImgUrlByCut = ""
      this.backImgUrlByCut = ""
      this.modalData = ""
      this.modalData = data
      this.showModal = true
      await this.getYuanImg()
      await this.getCutImg();
    },
    async getYuanImg() {
      this.yuanImgSpinningStatus = true
      const res = await getYuanImgByCoverPic(this.modalData.id)
      this.yuanImgSpinningStatus = false
      if (res.status !== "200") return;
      this.backImgUrlByYuan = res.data.coinKindVersionRealBackImage
      this.frontImgUrlByYuan = res.data.coinKindVersionRealImage
    },
    async getCutImg() {
      this.cutImgSpinningStatus = true
      const res = await getCutImgByCoverPic(this.modalData.id)
      this.cutImgSpinningStatus = false
      if (res.status !== "200") return;
      this.frontImgUrlByCut = res.data.cutFrontImage
      this.backImgUrlByCut = res.data.cutBackImage
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.frontImgUrlByCut) return this.$message.warning("抠图图片还未成功，请稍后...");
      const res = await postSetCoverPic({
        id: this.modalData.id,
        cutFrontImage: this.frontImgUrlByCut,
        cutBackImage: this.backImgUrlByCut
      })
      if (res.status !== "200") return;
      this.$message.info("设置成功")
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.showModal = false
      this.modalData = ""
    },
    /** 展示大图 */
    handleShowBigImg(imgList) {
      this.$previewImg({
        list: [{ img_url: imgList[0] }, { img_url: imgList[1] }],
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style scoped lang="scss">
.top-title {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #2d8cf0;
}
.content-modal {
  width: 100%;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-box {
    padding: 10px;
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7c7a7a;
    img {
      width: 200px;
      //height: 200px;
    }
  }
}
</style>
