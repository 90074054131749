<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="上传--建议最大上传50组（100张图片）"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <template #footer>
      <div class="footer-box">
        <div class="footer-right">
          <a-checkbox v-model="checkedIsDiKu">是否同步到底库</a-checkbox>
        </div>
        <div class="footer-right">
          <a-button key="back" @click="cancelPushModal">取消</a-button>
          <a-button key="submit" type="primary" @click="confirmPushModal">确定上传</a-button>
        </div>
      </div>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 220px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.coinKindItemId"
            :value="item.coinKindItemId"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          @change="changeVersion"
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="curItem.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
          >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
        </a-select>
      </div>
      <div class="images">
        <div class="images-title">
          <div>正</div>
          <div>反</div>
        </div>
        <div class="up-image">
          <div class="up-left">
            <DraggableImg
                ref="draggableImgEl"
                :list="coverList"
                :multipleStatus="true"
                :maxLength="100"
                @dragImg="handleDragCoverImgList($event)"
            />
          </div>
          <div class="up-right"></div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"
import DraggableImg from "@/components/DraggableImg/index.vue"
export default {
  components: {DraggableImg},
  data() {
    return {
      checkedIsDiKu: true,
      untils,
      pushModal: false,
      curItem: {},
      coverList: [],
      imgKey: "",
      coinList: [],
      coinItemList: [],
      versionList: [],
      checkBoxTest: []
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.coverList = [];
        this.curItem = {};
      }
    },
  },
  methods: {
    async show() {
      this.pushModal = true;

      const { coinId, itemId, versionId } = this.$parent.params;
      if (!coinId) return;
      // 大币种列表
      const { bigCoinList } = this.$parent;
      const coin = bigCoinList.find((el) => {
        return el.coinId === coinId;
      });
      await this.coinSearch(coin.coinName);
      this.$set(this.curItem, "coinKindId", coinId);
      this.$set(this.curItem, "coinKindName", coin.coinName);
      // await this.changeCoin();
      await this.getSmallCoinList()
      if (!itemId) return;
      this.$set(this.curItem, "coinKindItemId", itemId);
      const coinItem = this.coinItemList.find((el) => {
        return el.coinKindItemId === this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      await this.getVersion()
      const versionItem = this.versionList.find((el) => {
        return el.coinVersionId === versionId;
      });
      this.$set(this.curItem, "coinKindItemVersionId", versionId);
      this.$set(this.curItem, "coinKindItemVersionName", versionItem.coinVersionName);
    },
    /** 图片移动完成 */
    handleDragCoverImgList(list) {
      this.coverList = list
    },
    /** 大币种改变 */
    async changeCoin() {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.$set(this.curItem, "coinSid", coin.sid);
      this.$set(this.curItem, "coinKindName", coin.coinKindName);
      this.$set(this.curItem, "coinKindId", coin.id);
      await this.getSmallCoinList();
    },
    //  选择小币种
    async changeCoinItem() {
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!this.curItem.coinKindItemId) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.coinKindItemId === this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      await this.getVersion()
    },
    // 版别改变
    changeVersion() {
      if (!this.curItem.coinKindItemVersionId) return;
      const version = this.versionList.find((el) => {
        return el.coinVersionId === this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinVersionName;
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await getBigCoinList(str, [5])
      if (res.status !== "200") return;
      const { records } = res.data;
      this.coinList = records;
    },
    /** 搜索小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinItems", {
        params: {
          coinId: this.curItem.coinKindId,
        },
      });
      if (res.status !== '200') return;
      this.coinItemList = res.data;
    },
    /** 获取版别 */
    async getVersion() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinVersions", {
        params: {
          coinId: this.curItem.coinKindId,
          coinItemId: this.curItem.coinKindItemId,
        },
      });
      if (res.status !== '200') return;
      this.versionList = res.data;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.curItem;
      const pushImages = [];
      let count = -1;
      this.coverList.forEach((el, index) => {
        if (index % 2 == 0) {
          count++;
          pushImages[count] = [];
        }
        pushImages[count].push(el.url);
      });
      const coverImages = pushImages.map((el) => {
        return el.join(",");
      });
      const res = await this.axios.post("/dq_admin/duibanSampleData/manualUpload", {
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        coverImages,
        operAsycType: this.checkedIsDiKu ? 'sampleToDiku' : 'sampleToRemoveDiku'
      });
      if (res.status !== '200') return;
      this.checkBoxTest = []
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
      this.coverList = []
      this.$refs.draggableImgEl.scoreList = []
      this.$refs.draggableImgEl.fileList = []
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
::v-deep .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.images {
  width: 350px;
  margin-top: 10px;
  .images-title {
    width: 208px;
    display: flex;
    margin-bottom: 10px;
    div {
      width: 104px;
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
  .up-image {
    display: flex;
    .up-left {
      width: 350px;
      display: flex;
      flex-wrap: wrap;
    }
    .up-right {
      .right-list {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .right-item {
        width: 150px;
        height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>